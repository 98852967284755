
import React from "react" 
import { Helmet } from "react-helmet";       
import Layout from "../components/layout/Layout";
import {ContactForm} from "../components/form/ContactForm";
 
const ZadostPage = ({ location}) => {

  return (
      <>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Žádost o splátkový kalendář | Exekutorský úřad Praha 9</title> 
        </Helmet>  

        <Layout location={location} pageContext={{title:"Žádost o splátkový kalendář", pageId: '39', parent: '34'}} > 
            <div className="mx-auto w-full  relative  ">
               <div className="mx-auto max-w-6xl w-full px-8 pb-10 lg:px-14   z-auto  relative">    
                    <ContactForm />
                </div>
            </div>
        </Layout>
      </>

  )
}
 
export default ZadostPage
 