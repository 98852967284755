import React, { useState, useRef } from "react"; 
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"; 
import { Error }  from "./Error";
import axiosInstance from "../../client";
import { Loading } from "../layout/Loading"; 
import ReCAPTCHA from "react-google-recaptcha";
import {FaAsterisk} from "react-icons/fa"
 

export const ContactForm = ({}) => {
   
  const [loading, setLoading] = useState(false);   
 
  const { 
    register, 
    handleSubmit,
    reset, 
    formState: { errors } 
  } = useForm(); 
 
  const recaptchaRef = useRef();
  const onSubmitWithReCAPTCHA = async (data) => {
    const token = await recaptchaRef.current.executeAsync(); 
    onSubmit(data,token);
  }
 
  const onSubmit = async data => {  
    setLoading(true); 
    await axiosInstance.post('forms/send/1/', data).then(res => { 
      reset({});
      navigate('/formular-odeslan'); 
      setLoading(false); 
    }).catch(err => {   
      console.log(err?.data?.message)    
      setLoading(false);
    })  
     
  };
  
    return(
        <>

          {loading ? <Loading loading={loading} /> 
          
        : 
        
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 ">
            <div className="col-span-2"> 
                   
                    <form  onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}  > 
                    <input type="hidden" name="form_name" defaultValue="contact"  {...register("form_name", { required: true })} />
                     
                    <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 ">
                    

                        <div className="sm:col-span-3">
                          <label htmlFor="fname" className="block text-sm font-bold text-gray-700">
                            Jméno <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                          </label>
                          <div className="mt-1">
                            <input type="text" name="fname" id="fname"  {...register("fname", { required: true })} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors.fname && 
                              <Error text="Vyplňte jméno" />
                            }
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label htmlFor="surname" className="block text-sm font-bold text-gray-700">
                            Příjmení <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                          </label>
                          <div className="mt-1">
                            <input type="text" name="surname" id="surname"  {...register("surname", { required: true })} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors.surname && 
                              <Error text="Vyplňte příjmení" />
                            }
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label htmlFor="street" className="block text-sm font-bold text-gray-700">
                            Ulice, číslo domu <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                          </label>
                          <div className="mt-1">
                            <input type="text" name="street" id="street"  {...register("street", { required: true })} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors.street && 
                              <Error text="Vyplňte ulici" />
                            }
                          </div>
                        </div>
                        
 
                        
                        <div className="sm:col-span-4">
                          <label htmlFor="city" className="block text-sm font-bold text-gray-700 ">
                            Město <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                          </label>
                          <div className="mt-1">
                            <input id="city"   type="text" {...register("city", { required: true })} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors.city && 
                              <Error text="Vyplňte město" />
                            }
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="zip" className="block text-sm font-bold text-gray-700">
                            PSČ <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                          </label>
                          <div className="mt-1">
                            <input type="text" id="zip" {...register("zip", { required: true })} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors.zip && 
                              <Error text="Vyplňte PSČ" />
                            }
                          </div>
                        </div> 

                        <div className="sm:col-span-4">
                          <label htmlFor="email" className="block text-sm  text-gray-700 ">
                            E-mail  
                          </label>
                          <div className="mt-1">
                            <input id="email" name="email" type="email" {...register("email")} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                             
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <label htmlFor="tel" className="block text-sm font-bold text-gray-700">
                            Telefon <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                          </label>
                          <div className="mt-1">
                            <input type="text" name="tel" id="tel" {...register("tel", { required: true })} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors.tel && 
                              <Error text="Vyplňte telefon" />
                            }
                          </div>
                        </div> 
                        <div className="sm:col-span-6">
                          <label htmlFor="spisovaznacka" className="block text-sm font-bold text-gray-700 ">
                            Spisová značka exekuce <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                          </label>
                          <div className="mt-1">
                            <input id="spisovaznacka"  type="text" {...register("spisovaznacka", { required: true })} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors.spisovaznacka && 
                              <Error text="Vyplňte spisovou značku" />
                            }
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label htmlFor="zamestnavatel" className="block text-sm   text-gray-700 ">
                            Zaměstnavatel
                          </label>
                          <div className="mt-1">
                            <input id="zamestnavatel"  type="text" {...register("zamestnavatel")} className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label htmlFor="splatka" className="block text-sm font-bold text-gray-700 ">
                            Výše měsíční splátky <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                          </label>
                          <div className="mt-1">
                            <input id="splatka"  type="text" {...register("splatka", { required: true })} placeholder="1000 - 200 000 Kč" className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors.splatka && 
                              <Error text="Vyplňte splátku" />
                            }
                          </div>
                        </div>
                      <div  className="sm:col-span-6" >
                        <label htmlFor="street_address" className="block text-sm   text-gray-700">
                          Zpráva
                        </label>
                        <div className="mt-1">
                                                    
                          <textarea className="rounded border border-gray-300 w-full h-32" {...register("text")} ></textarea>
                         
                        </div>
                      </div>
                    </div> 
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6Lffi1odAAAAACfkp61rf3BaSFsVQuDo0wjbKXBF"
                    />   
                    <div className="mt-8">
                    
                    {loading ? (
                        
                        <button disabled className="bg-gray-200 text-gray-800 font-bold py-4 px-20  rounded cursor-default">
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span>Odesílání...</span></button>
                      ) : (
                        <>
                        <button type="submit" className="bg-gray-700 text-white font-bold py-4 px-20  rounded hover:bg-gray-600"
                           
                        >Odeslat</button>
                        
                        </>
                      )}
                         
                    </div> 

                    </form>
                </div>
                
              </div>
            }
        </>
    )
}